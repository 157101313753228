export const trackEvent = async (eventData: Record<string, any>) => {
  try {
    // Log eventData for debugging
    // console.log('Received event data:', eventData);
    const PIXEL_ID = process.env.pix_id;
    const ACCESS_TOKEN = process.env.ACCESS_TOKEN;
    
    // const response = await 
    fetch(
      `https://graph.facebook.com/v21.0/${PIXEL_ID}/events?access_token=${ACCESS_TOKEN}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: [eventData],
        }),
      }
    );

    // Check the response status
    // if (!response.ok) {
    //   const errorText = await response.text();
    //   console.error('Error response:', errorText);
    // }

    // const result = await response.json();
    // console.log('Event tracked successfully:', result);
  } catch (error) {
    console.error('Error handling request:', error);
  }
  // try {
  //   // const trackEventResponse = 
  //   fetch('/api/conversions', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(eventData),
  //   });

  //   // if (!trackEventResponse.ok) {
  //   //   const errorText = await trackEventResponse.text();
  //   //   console.error('Error sending event:', errorText);
  //   // } else {
  //   //   const trackEventResult = await trackEventResponse.json();
  //   //   console.log('Event tracked successfully:', trackEventResult);
  //   // }
  // } catch (error) {
  //   console.error('Error in tracking event:', error);
  // }
};
